import React from "react";
import {Center} from "@mantine/core"


export const Background = (props: any) => {
    return <Center
        h={"100vh"} w={"100vw"} sx={{
        backgroundColor: "#f1f1f1"
    }}>
        {props.children}
    </Center>
}