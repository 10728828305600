import React from "react";

// @ts-ignore
import Script from '@gumgum/react-script-tag';
import {useOAuthParameters} from "../utils/oauth_hook";

export const GoogleSignInButton = () => {

    const parameters = useOAuthParameters()

    return !parameters ? <> </> : <>

        <Script
            src="https://accounts.google.com/gsi/client"
            type="text/javascript"
            async
            defer
        />


        <div id="g_id_onload"
             data-client_id="637787070923-7ms3ghmhu7138oqjg9k8p3bt8l73vrok.apps.googleusercontent.com"
             data-context="signin"
             data-ux_mode="redirect"
             data-login_uri={process.env.REACT_APP_ACCURAS_OAUTH_HOST + "/google/sign-in?redirect_uri=" + parameters!.redirect_uri + "&client_id=" + parameters!.client_id + "&state=" + parameters!.state + "&response_type=" + parameters!.response_type + "&scope=" + parameters!.scope}
             data-auto_select="true"
             data-itp_support="true">
        </div>

        <div className="g_id_signin"
             data-type="standard"
             data-locale="en_US"
             data-width={320 - 64}
             data-shape="rectangular"
             data-theme="outline"
             data-text="signin_with"
             data-size="large"
             data-logo_alignment="left">
        </div>

    </>
}